/*
 * Font face mixin
 */
@mixin font-face($font-family, $file-path, $font-weight:'normal', $font-style:'normal') {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype'),
    url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }

}

/**
 * Media Queries
 */
@mixin respondto($device) {

  @each $name,
  $point in $breakpoints {
    @if $name==$device {
      @media #{$point} {
        @content;
      }
    }
  }
}

// Transition
@mixin transition($properties...) {

  @if length($properties)>=1 {
    transition: $properties;
  }

  @else {
    transition: all .3s ease-in-out 0s;
  }
}


// Margins
@for $i from 1 through 20 {
  .margin-top-#{$i} {
    margin-top: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .margin-left-#{$i} {
    margin-left: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .margin-right-#{$i} {
    margin-right: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .margin-top-#{$i}--xs {
    @include respondto(phone) {
      margin-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-bottom-#{$i}--xs {
    @include respondto(phone) {
      margin-bottom: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-bottom-#{$i}--ld {
    @include respondto(maxTablet) {
      margin-bottom: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-top-#{$i}--ld {
    @include respondto(maxTablet) {
      margin-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-left-#{$i}--ld {
    @include respondto(maxTablet) {
      margin-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-right-#{$i}--ld {
    @include respondto(maxTablet) {
      margin-right: #{$i}rem;
    }
  }
}

// Paddings
@for $i from 1 through 20 {
  .padding-top-#{$i} {
    padding-top: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .padding-bottom-#{$i} {
    padding-bottom: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .padding-left-#{$i} {
    padding-left: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  @include respondto(phone) {
    .padding-left-#{$i}--xs {
      padding-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-right-#{$i} {
    padding-right: #{$i}rem;
  }
}

@for $i from 1 through 20 {
  .padding-bottom-#{$i}--ld {
    @include respondto(maxTablet) {
      padding-bottom: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-top-#{$i}--ld {
    @include respondto(maxTablet) {
      padding-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-left-#{$i}--ld {
    @include respondto(maxTablet) {
      padding-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-right-#{$i}--ld {
    @include respondto(maxTablet) {
      padding-right: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-left-#{$i}--dt {
    @include respondto(onlyDesktop) {
      padding-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-right-#{$i}--dt {
    @include respondto(onlyDesktop) {
      padding-right: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-top-#{$i}--dt {
    @include respondto(onlyDesktop) {
      padding-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .padding-bottom-#{$i}--dt {
    @include respondto(onlyDesktop) {
      padding-bottom: #{$i}rem;
    }
  }
}


@for $i from 1 through 20 {
  .margin-top-#{$i}--dt {
    @include respondto(onlyDesktop) {
      margin-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-bottom-#{$i}--dt {
    @include respondto(onlyDesktop) {
      margin-bottom: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-left-#{$i}--dt {
    @include respondto(onlyDesktop) {
      margin-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-right-#{$i}--dt {
    @include respondto(onlyDesktop) {
      margin-right: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-top-#{$i}--sm {
    @include respondto(phoneSm) {
      margin-top: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-bottom-#{$i}--sm {
    @include respondto(phoneSm) {
      margin-bottom: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-left-#{$i}--sm {
    @include respondto(phoneSm) {
      margin-left: #{$i}rem;
    }
  }
}

@for $i from 1 through 20 {
  .margin-right-#{$i}--sm {
    @include respondto(phoneSm) {
      margin-right: #{$i}rem;
    }
  }
}

@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

/**
 * Flexbox Container
 */
@mixin flex($wrap: nowrap, $direction: row, $justify: flex-start, $align: stretch, $content: stretch, $order: 1, $inline: false) {
  @if $inline==true {
    display: inline-flex;
  }

  @if $inline==false {
    display: flex;
  }

  @if $wrap !=nowrap {
    flex-wrap: $wrap;
  }

  @if $direction !=row {
    flex-direction: $direction;
  }

  @if $justify !=flex-start {
    justify-content: $justify;
  }

  @if $align !=stretch {
    align-items: $align;
  }

  @if $content !=stretch {
    align-content: $align;
  }

  @if $order !=1 {
    order: $order;
  }
}

@mixin flex-percentage($percentage: 1) {
  @if $percentage !=1 {
    box-sizing: border-box;
    flex: 1 1 $percentage;
    max-width: $percentage;
  }

  @else {
    box-sizing: border-box;
    flex: 1;
    max-width: 100%;
  }
}


@for $i from 1 through 100 {
  .w-#{$i} {
    @include respondto(onlyDesktop) {
      width: #{$i}#{'%'};
    }
  }

  .w-#{$i}-ds {
    @include respondto(desktopSmall) {
      width: #{$i}#{'%'} !important;
    }
  }

  .w-#{$i}-ld {
    @include respondto(maxTablet) {
      width: #{$i}#{'%'} !important;
    }
  }
  .w-#{$i}-sm {
    @include respondto(phoneSm) {
      width: #{$i}#{'%'} !important;
    }
  }
}

@function rem($pixels, $context: $mainFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}

// size to one line
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}