// Flex
.display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-column--xs {
  @include respondto(phone) {
    flex-direction: column;
  }
}

.layout-column--sm {
  @include respondto(tablet) {
    flex-direction: column;
  }
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap--xs {
  @include respondto(phone) {
  flex-wrap: wrap;
  }
}
.flex-auto {
  flex: 1 1 auto;
}

.align-items-center--xs {
  @include respondto(phone) {
    align-items: center;
  }
}

.align-items-initial--xs {
  @include respondto(phone) {
    align-items: initial;
  }
}

.justify-content-between--xs {
  @include respondto(phone) {
    justify-content: space-between;
  }
}

.order-2-row-xs {
  @include respondto(phone) {
    order: 2;
  }
}

@each $dimmension in 1, 2, 3, 4, 5, 6, 7 {
  .order-#{$dimmension}-xs {

    @include respondto(phone) {
      @include flex($direction:column);
      order: $dimmension;
    }
  }
}

@each $dimmension in 1, 2, 3, 4, 5, 6, 7 {
  .order-row-#{$dimmension}-xs {

    @include respondto(phone) {
      @include flex($direction:row);
      order: $dimmension;
    }
  }
}

@each $dimmension in 30, 40, 50, 60 {
  .flex-#{$dimmension} {
    @include flex-percentage(#{ $dimmension + '%'});

    @include respondto(phone) {
      @include flex-percentage();
    }
  }
}

.flex-100--xs {
  @include respondto(phone) {
    @include flex-percentage();
  }
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0 auto;
}

.flex-1--ld {
  @include respondto(maxTablet) {
    flex: 1;
  }
}

.flex-0--ld {
  @include respondto(maxTablet) {
    flex: 0 auto;
  }
}

.flex-1--sm {
  @include respondto(phoneSm) {
    flex: 1;
  }
}

.flex-0--sm {
  @include respondto(phoneSm) {
    flex: 0 auto;
  }
}

.flex-column-ld {
  @include respondto(maxTablet) {
    flex-direction: column;
  }
}