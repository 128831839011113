.title-component {
  margin-top: 23px;

  span {
    color: $grey-dark4;
  }

  h1 {
    letter-spacing: -2px;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }

  .select-section {
    margin-bottom: 41px;

    @include respondto(phone) {
      margin-bottom: 21px;
    }
  }

  .wrapper {

    span {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 300;
      font-size: 2.2rem;
      line-height: 3.2rem;

      @include respondto(phone) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  } 
  
  .wrapper.roi-calculator {
    max-width: 552px;
    
    span {
      font-size: 16px;
      line-height: 22px;
      font-style: normal;
      font-weight: 400;
    }
  }

  a {
    font-size: 2.2rem;
    line-height: 3.2rem;
    
    @include respondto(phone) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  p {
    a {
      font-size: 2.2rem;
      line-height: 3.2rem;

      @include respondto(phone) {
        font-size: 1.6rem;
        line-height: normal;
      }
    }
  }

  .bullet-list {
    span {
      color: $grey-dark;
    }
  }

  .info-image {
    margin-bottom: 3.5rem;

    img {
      margin: 0 4rem;
    }
  }

  .centered-block {
    p {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 300;
      max-width: 110rem;
      margin: 0 auto;

      @include respondto(phone) {
        line-height: 2.1rem;
      }
    }
  }

  &.single-title {
    min-height: 25rem;

    .wrapper {
      p {
        margin-bottom: 3rem;
        max-width: 110rem;
      }

      @include respondto(phone) {
        margin-left: 0;
        max-width: 100%;

        p {
          font-size: 1.6rem;
          line-height: normal;
        }
      }
    }

    @include respondto(phone) {
      min-height: auto;
    }
  }

  .bk-waves {
    //background-image: url('../resources/images/stripe-line-bk.png');
    background-size: contain;
    background-position: center center;
    height: 13rem;
    opacity: .5;
    top: 0;
    z-index: -1;

    &.sm-height {
      height: 11rem;
    }

    @include respondto(phone) {
      background-size: cover;
      height: 10rem;
      opacity: initial;
      overflow: hidden;
      top: 0;
    }
  }
}