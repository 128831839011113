.select-wrapper {
  position: relative;

  select {
    background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
    border: 1px solid $grey-dark15;
    border-radius: .4rem;
    color: $grey-dark7;
    cursor: pointer;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 1.4rem;
    height: 4.2rem;
    padding-left: 4rem;
    width: 100%;

    @include respondto(phone) {
      font-size: 1.6rem;
      padding-left: 2rem;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &:before {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid $green-light10;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translate(-50%, -50%);

    @include respondto(phone) {
      left: 1.2rem;
    }

  }

  &:after {
    content: '\f078';
    color: $grey-light2;
    font-family: $font-awesome;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    pointer-events: none;
    right: 0;
    transform: translate(50%, -50%);
  }

  @include respondto(maxTablet) {
    margin: 1rem 0;
  }

  &-sm {
    margin: 0 auto 6.5rem auto;
    max-width: 15rem;

    @include respondto(phone) {
      max-width: 17rem;
    }

    &:before {
      content: none;
    }

    select {
      padding-left: 1.5rem;
    }
  }
}

.shadow-select {
  width: 47.5rem;
  position: relative;
  margin-left: auto;

  @include respondto(phone) {
    width: 100%;
  }

  select {
    background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
    border: 1px solid $grey-dark15 !important;
    border-radius: .4rem;
    color: #656565;
    cursor: pointer;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 2.2rem;
    height: 4.2rem;
    padding-left: 2.6rem;
    width: 100%;

    @include respondto(phone) {
      font-size: 1.6rem;
      padding-left: 1.8rem;
    }

    &:hover {
      border-radius: .4rem;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: '\f078';
    color: $grey-light2;
    font-family: $font-awesome;
    font-size: 0.6rem;
    position: absolute;
    top: 50%;
    pointer-events: none;
    right: 2rem;
    transform: translate(50%, -50%);
  }

  .checkbox-custom-label {
    color: $grey-dark;
  }
}