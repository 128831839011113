.clearfix {
  clear: both;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.display-grid {
  display: grid;
}

.display-none {
  display: none;
}

.display-block--xs {
  @include respondto(phone) {
    display: block !important;
  }
}

.display-block--ld {
  @include respondto(maxTablet) {
    display: block !important;
  }
}

.display-inline-block {
  display: inline-block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center-xs {
  @include respondto(phone) {
    text-align: center;
  }
}

.text-center-ld {
  @include respondto(maxTablet) {
    text-align: center;
  }
}

.text-right-ld {
  @include respondto(maxTablet) {
    text-align: right;
  }
}

.text-left-xs {
  @include respondto(phone) {
    text-align: left;
  }
}

.full-width {
  width: 100%;
}

.full-width--xs {
  @include respondto(phone) {
    width: 100%;
  }
}

.full-width--ld {
  @include respondto(maxTablet) {
    width: 100%;
  }
}

.full-height {
  height: 100%;
}

.hidden-y {
  overflow-y: hidden;
}

.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.green-bb {
  border-bottom: 15px solid $blue-light12!important;
}

.green-bt {
  border-top: 15px solid $blue-light7!important;
}

.grey-bb {
  border-bottom: 15px solid $grey-light;

  &-20 {
    border-bottom: 20px solid $grey-light;
  }

  @include respondto(phone) {
    border-bottom: 9px solid $grey-light;
  }

  &.line-xs {
    @include respondto(phone) {
      border-bottom: 1px solid #D8D8D8;
    }
  }
}

.br-2 {
  border-radius: 2rem;
}

.no-br-xs {
  @include respondto(phone) {
    border-radius: 0;
  }
}

.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center';
}

.object-position-right {
  object-position: right;
}

.object-position-top {
  object-position: top;
}

.object-fit-contain {
  object-fit: contain;
  font-family: 'object-fit: contain; object-position: center';
}

.object-position-center {
  object-position: center;
}

.background-position {
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

// ie class for object-fit fix
.custom-object-fit {
  background-size: cover;
  background-position: center center;
  height: 100%;
  position: relative;

  .object-fit-cover {
    opacity: 0;
  }
}

.sm-list {
  padding-left: 1.6rem;

  li {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}

.bullet-list {
  list-style: none;

  p,
  li {
    color: $grey-dark;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 2.5rem;

    &:before {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 7rem;
      border: 2px solid $green-light1;
      margin: 0 1rem 0 -2rem;
    }
  }

  li {
    margin-bottom: 2rem;

    a {
      font-size: 1.8rem;
      line-height: 2.5rem
    }

    @include respondto(phone) {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }

  &.greener {

    p,
    li {
      &:before {
        border: 2px solid $green-light10;
        margin-bottom: 3px;
      }
    }
  }
}

ul {
  &.bullet-list {
    padding-left: 3rem;

    @include respondto(phone) {
      padding-left: 2rem;
    }
  }
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.opacity-80 {
  opacity: .8;
}

.blue-light {
  a {
    color: $blue-light2;
  }
}

.bordered-item {
  border: 1px solid #E0E7ED;
  border-radius: 4px;
}

.no-margin {
  margin: 0;
}

.no-margin--xs {
  @include respondto(phone) {
    margin: 0 !important;
  }
}

.no-padding-xs {
  @include respondto(phone) {
    padding: 0 !important;
  }
}

.no-padding-xs-sm {
  @include respondto(maxTablet) {
    padding: 0;
  }
}

.blue-section {
  h2 {
    color: $blue-dark7;
  }
}

//Colors
.grey-light-bk {
  background-color: $grey-light;
}

.red-color {
  color: $red !important;
}

.pastelGreen{
  color: $pastelGreen
}

//Video
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 53px;
  height: 0;
  overflow: hidden;
  z-index: 1;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.link-icon {
  max-width: 4rem;
}

.disabled {
  opacity: 0.18;

  select,
  input,
  textarea {
    cursor: not-allowed !important;
  }
}

.video-info {
  color: $grey-dark4;
  opacity: .8;
  padding: 1.5rem 0;
}

.reset-padding-xs {
  @include respondto(phone) {
    padding: initial;
  }
}

.container {
  @include respondto(largeDesktop) {
    width: 1270px;
  }
}

.description-item {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.2rem;

  @include respondto(phone) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.bk-waves {
  background: url('../../../../static/images/apr-waves.png');
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 18.6rem;
  position: absolute;
  z-index: 2;
  bottom: -75px;
  left: 0;

  @include respondto(phone) {
    background-size: cover;
    bottom: -45px;
    height: 10rem;
  }
}