.button {
  border-radius: .6rem;
  display: inline-block;
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-family: 'Poppins', sans-serif!important;
  font-weight: 500;
  padding: 1rem 2.5rem;
  text-decoration: none;

  &-reset {
    background: none;
    border: none;
  }

  &-blue {
    background-color: $blue-dark8;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: $green-dark;
    }
  }

  &-blue-light {
    background-color: $blue-dark7;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: $black;
    }
  }

  &-blue-dark {
    background-color: $blue-dark8;
    color: $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: $blue-dark9;
    }
  }

  &-green {
    background-color: $green-light11;
    color: $white;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &-lg {
    padding: 1rem 8rem;
  }

  &-sm {
    background-color: $blue-dark9;
    color: $white;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: .8rem 5rem;

    &:hover,
    &:focus {
      color: $white;
      background-color: $black;
    }
  }
}