/*** Fonts */
$montserrat-thin:'MontserratThin';
$montserrat-light:'MontserratLight';
$montserrat-regular:'MontserratRegular';
$montserrat-medium:'MontserratMedium';
$montserrat-black:'MontserratBlack';
$montserrat-bold:'MontserratBold';

// Font Awesome
$font-awesome: 'FontAwesome';

// Font

// Size
$mainFontColor: #ffffff;
$mainFontSize: 16px;
$mainFontWeight: 400;
$mainLineHeight: 1.4;

/**
 * Media Queries
 */
$breakpoints: (largeDesktop: '(min-width: 1300px)',
  customDesktop: '(min-width: 1440px)',
  desktop: '(min-width: 1200px) and (max-width: 1599px)',
  desktopSmall: '(min-width: 992px) and (max-width: 1199px)',
  onlyDesktop: '(min-width: 992px)',
  maxDesktop: '(max-width: 1200px)',

  desktopAndTablet: '(min-width: 768px)',
  desktopSmallAndTablet: '(min-width: 768px) and (max-width: 1299px)',

  maxTablet: '(max-width: 991px)',
  maxTabletSmaller: '(max-width: 968px)',
  maxTabletSmaller2: '(max-width: 952px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',

  phone: '(max-width: 767px)',
  phoneXS: '(max-width: 600px)',
  phoneSmall: '(max-width: 480px)',
  phoneSm: '(max-width: 400px)',
  iphone5: '(max-width: 350px)'
);

/**
 * Colors
 */
$black: #000000;
$black2: #363333;
$white: #ffffff;
$grey: #86939E;
$grey-light: #f6f6f6;
$grey-light2: #A8AAB7;
$grey-light3: #F9F9F9;
$grey-dark: #43484D;
$grey-dark2: #6B6767;
$grey-dark3: #666666;
$grey-dark4: #5E6977;
$grey-dark5: #999999;
$grey-dark6: #6D7278;
$grey-dark7: #656565;
$grey-dark8: #354052;
$grey-dark9: #979797;
$grey-dark10: #E6EAEE;
$grey-dark11: #BEBEBE;
$grey-dark12: #D8D8D8;


$blue-light: #358ED7;
$blue-light2: #0091FF;
$blue-light3: #0D67A0;
$blue-light4: #1991C7;
$blue-light5: #42A1E8;
$blue-light6: #658BCA;
$blue-dark: #0c3861;
$blue-dark2: #011938;
$blue-dark3: #003764;
$blue-dark4: #0D2442;
$blue-dark5: #153962;
$blue-dark6: #16698F;
$blue-dark7: #2C688F;
$blue-dark8: #003DA5;
$blue-dark9: #001489;
$blue-dark10: #0249C6;
$blue-dark11: #005EFF;


$orange: #E49F3E;
$orange-light1: #DA932C;
$orange-light2: #F7B500;
$orange-light3: #E87722;

$red: #E02020;
$red-light1:#FA1000;

$green: #4BB5B8;
$green-light: #D0E7D8;
$green-light1: #D4EADC;
$green-light2: #6DD400;
$green-light3: #7DC855;
$green-light4: #66B92E;
$green-light5: #97D377;
$green-light6: #409D54;
$green-light7: #F1F9E6;
$green-light8: #73C700;
$green-light9: #42b02a;
$green-light10: #6BCABA;
$green-light11: #43B02A;
$green-dark: #00A785;

//Dashboard colors
$black3: #101010;
$black4: #090909;
$black5: #354052;
$black6: #0E0D0D;
$black7: #222222;


$orange1: #E2A957;
$blue-light5: #1991EB;
$blue-light7: #32C5FF;
$blue-light8: #0C3861;
$blue-light9: #40AFC9;
$blue-light10: #1875F0;
$blue-light11: #109BBB;
$blue-light12: #6699cc;
$blue-dark9: #0D3861;


$grey-light4:#979797;
$grey-light5: #E6EAEE;
$grey-light6: #A1A1A1;
$grey-dark11: #7E7E7E;
$grey-dark12: #808080;
$grey-dark13: #F5F5F5;
$grey-dark14: #AAA8A8;
$grey-dark15: #ced0da;
$grey-dark16: #BEBEBE;
$grey-dark17: #333333;

$french-gray: #c6c7ca;

$green: #1d8365;
$green2: #409d54;
$dark-pink: #b42c66;
$blood-orange: #f25100;
$nice-blue: #156ebb;
$blackHighest: #000000;
$black: #1d1d1d;
$accent: #207232;
$blue: #013664;
$blue-light: #c7e1ed;
$white: #ffffff;
$silver: #9b9b9d;
$silver-light: #b6b6b7;
$grey: #7c7c7c;
$pastelGreen:#7ED570;

// Light Color
$white-color: rgba(255, 255, 255, 0.5);
// Dark Color
$black-color: rgba(0, 0, 0, 0.5);
//ready color
