.single-item {
  padding: 7rem 0;

  @include respondto(phone) {
    padding: 4rem 0 2.2rem;
  }

  &.lg-padding {
    padding-bottom: 14rem;

    @include respondto(phone) {
      padding-bottom: 3rem;
    }
  }

  .description-item {
    color: $grey-dark4;

    @include respondto(phone) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  .img-wrapper {
    height: 41rem;

    @include respondto(phone) {
      height: 11.7rem;
    }
  }

  .centered-text {
    color: $grey-dark4;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 300;
    margin: 0 auto 7rem auto;
    max-width: 85%;

    @include respondto(phone) {
      margin-bottom: 2rem;
    }
  }
}