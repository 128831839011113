@media print {

  body,
  html,
  #wrapper,
  .container {
    width: 100% !important;
  }

  .literature-block,
  .dashboard-block {
    .bottom-content {
      &.margin-top-12 {
        margin-top: 2rem !important;
      }
    }

    .slick-slide,
    .slick-track,
    .slick-slide .slick-active .slick-current {
      width: 100% !important;
    }

    .top-title {
      justify-content: inherit;

      &.margin-bottom-12 {
        margin-bottom: 2rem !important;
      }
    }
  }

  .imprint-prod-item {
    width: 19% !important;
  }

  .YTD-item {
    width: 10% !important;
  }

  .actions-item {
    width: 12% !important;
  }

  .button-item,
  .value-sm-item {
    width: 17% !important;
    margin-left: 0 !important;
  }

  .description-item {
    width: 30% !important;
  }

  .value-item {
    margin-left: 0 !important;
  }

  .YTD-item {
    margin-right: 0 !important;
  }


  .media-slider-dotsFalse {
    .slider-wrapper {
      .slider-content {
        padding: 2rem 0 !important;
        max-width: 100% !important;
        height: inherit !important;
      }
    }
  }

  .media-slider-dotsFalse {
    height: 25rem !important;
  }

  .content-wrapper {
    height: 100% !important;
    min-height: 25rem !important;
  }

  .hidden-xs {
    display: block !important;
  }

  .dashboard-table {
    .table-with-scroll {
      .header-table {
        display: block !important;
        padding: 2rem 0;

        p {
          text-align: center;
        }
      }

      .table-description-item {
        width: 30% !important;
      }

      .block-item {
        text-align: center;

        p {
          max-width: 100% !important;
        }
      }

      .image-item {
        width: 12% !important;
      }
    }

    .flex-wrap--xs {
      flex-wrap: nowrap !important;
    }

    .order-2-xs {
      order: 0;
    }

    .order-1-xs {
      order: 0;
    }
  }

  .dashboard-block {
    width: calc(50% - 2.2rem) !important;
    border: 1px solid rgba(151, 151, 151, 0.4) !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 12rem !important;

    .media-slider-dotsFalse {
      .text-block {
        margin-left: 2rem !important;
        width: 68rem !important;
      }

      .slider-wrapper {
        .slider-content {
          padding: 6rem 0 2rem 0 !important;
          top: 50% !important;
        }
      }

    }

    .co-op-advertising-transactions .top-title {
      justify-content: inherit !important;
    }

    &__slider {
      width: calc(100% - 2rem) !important;
      margin-bottom: 20rem !important;
    }

    .dashboard-block__mobile {
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
    }
  }

  .layout-column--xs {
    flex-direction: row !important;
  }

  .add-blocks-dashboard {
    display: block !important;
  }

  .partners__downLoad,
  .partners__closeButton {
    display: none !important;
  }

  .partners__title {
    display: block !important;
    text-align: left !important;

    h2 {
      color: $grey-dark6 !important;
      font-size: 3.6rem !important;
      text-align: inherit !important;
    }
  }

  .partners__closeButton {
    display: none !important;
    position: relative !important;
  }

  .partners__title2 {
    display: flex;
    text-align: inherit;
    color: $blue-light11 !important;
  }

  .block-literature_blue {
    color: $white !important;

    &:before {
      background-color: $blue-light9 !important;
    }
  }

  .block-literature_green {
    color: $white !important;

    &:before {
      background-color: $green-light4 !important;
    }
  }

  .block-advertising-transactions_blue {
    background: linear-gradient(0deg, $blue-light5 0%, $blue-light6 100%) !important;
    color: $white !important;
  }

  .block-advertising-transactions_green {
    background-color: $green-light4 !important;
    color: $white !important;
  }
}

body {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @include respondto(desktopSmall) {
    font-size: 57.5%;
  }

  @include respondto(desktop) {
    font-size: 55%;
  }

  @include respondto(phone) {
    padding-top: 6rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
ol,
input,
textarea {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

input,
input:focus,
input:visited,
input:hover,
input:active,
textarea,
textarea:focus,
textarea:visited,
textarea:hover,
textarea:active,
select,
select:focus,
select:visited,
select:hover,
select:active,
button {
  border: none;
  border-color: transparent;
  outline-offset: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-shadow: none;
  outline-width: 0;
}


textarea {
  resize: none;
  width: 100%;
}

label {
  font-weight: normal;
}

input {
  font-size: 1.6rem;
  padding: 1rem;
}

iframe {
  border: 0;
}

a {
  @include transition();

  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
    @include transition();
  }
}

h1,
h2,
p {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400;
}

h1,
h2 {
  color: $grey-dark17;
  font-size: 5.2rem;
  line-height: 5.8rem;
  letter-spacing: -2px;

  @include respondto(phone) {
    font-size: 2.4rem;
    letter-spacing: -0.92px;
    line-height: 3rem;
  }
}

p {
  color: $grey-dark4;
  font-size: 2.2rem;
  line-height: 3.2rem;

  @include respondto(phone) {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
}

a {
  color: $blue-dark10;
  font-size: 1.6rem;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $blue-light5;
  }
}

//Video
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 53px;
  height: 0;
  overflow: hidden;
  z-index: 1;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-info {
  color: $grey-dark4;
  opacity: .8;
  padding: 1.5rem 0;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.no-margin--xs {
  @include respondto(phone) {
    margin: 0;
  }
}

.no-padding-xs {
  @include respondto(phone) {
    padding: 0;
  }
}

.reset-padding-xs {
  @include respondto(phone) {
    padding: initial;
  }
}

.container {
  @include respondto(largeDesktop) {
    width: 1270px;
  }
}

.description-item {
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.2rem;

  @include respondto(phone) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.bk-waves {
  background: url('../../../../static/images/apr-waves.png');
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 18.6rem;
  position: absolute;
  z-index: 2;
  bottom: -75px;
  left: 0;

  @include respondto(phone) {
    background-size: cover;
    bottom: -45px;
    height: 10rem;
  }
}

.dashboard-table {
  .strong {
    font-weight: 700 !important;
  }

  .table-with-scroll .table-description-item {
    text-align: center;
  }
}

.table-with-scroll {
  border: 1px solid $grey-light5;
  border-radius: 4px;
  background-color: $white;
  font-size: 1.4rem;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  float: left;
  position: relative;

  @include respondto(phone) {
    border: none;
    border-radius: 0;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  img {
    max-width: 6.6rem;
  }

  .table-row {
    &:last-child {
      .column-padding {
        @include respondto(phone) {
          border-bottom: 0;
        }
      }
    }
  }

  .image-item {
    width: 12%;

    @include respondto(phone) {
      width: 100%;
      align-items: center;
      margin-bottom: 1.5rem;
    }
  }

  .table-description-item {
    width: 30%;

    @include respondto(phone) {
      width: 60%;
      margin: 0 auto 1.5rem auto;
    }
  }

  .imprint-prod-item {
    width: 19%;

    @include respondto(phone) {
      width: 100%;
      margin-bottom: 1.5rem;
      align-items: center;
    }
  }

  .imprint-item {
    width: 20%;

    @include respondto(phone) {
      width: 40%;
      text-align: right;
      margin-right: 10%;

      .strong {
        text-align: left;
      }
    }
  }

  .member-value-item {
    width: 35%;

    @include respondto(phone) {
      width: 40%;
      margin-left: 10%;
    }
  }

  .YTD-item {
    width: 10%;

    @include respondto(phone) {
      width: 40%;
      text-align: right;
      margin-right: 10%;

    }
  }

  .value-sm-item {
    width: 17%;

    @include respondto(phone) {
      width: 40%;
      margin-left: 10%;

    }
  }

  .value-item {
    width: 23%;

    @include respondto(phone) {
      width: 40%;
      margin-left: 10%;
    }
  }

  .title-table {
    min-width: 100%;
    margin: 2rem 0 1rem;
  }

  .block-item {
    color: $grey-dark6;

    p {
      max-width: 24.8rem;
      font-size: 1.4rem;
      line-height: 1.9rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
      color: #354052;
      padding-right: 5px;

      @include respondto(phone) {
        max-width: 100%;
        padding-right: 10px;
        padding-left: 10px;
      }

    }

    .strong {
      color: $black5;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 700;
    }

    button,
    .table-link-item {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }
  }

  .button-item,
  .actions-item {
    width: 12%;
    text-align: center;

    @include respondto(maxTablet) {
      width: 17%;
    }

    @include respondto(phone) {
      width: 100%;
    }
  }

  .header-table {
    width: 100%;
    padding: 2rem;
    border-bottom: 1px solid $grey-light5;

    @include respondto(phone) {
      padding: 1rem;

      .member-value-item {
        p {
          text-align: right;
        }
      }
    }

    p {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.7rem;
      font-family: 'Poppins', sans-serif!important;

      @include respondto(phone) {
        text-align: left;
      }
    }

  }

  button,
  .table-link-item {
    background-color: transparent;
    border: 1px solid $grey-dark9;
    border-radius: .6rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    text-transform: uppercase;
    transition: all .3s ease-in-out 0s;
    padding: .5rem 1.9rem;
    color: $grey-dark6;

    &:hover {
      color: $black;
      border-color: $black;
    }
  }

  .margin-none {
    margin-left: -15px;
    margin-right: -15px;
  }

  .header-table {
    p {
      color: $grey-dark6;
    }
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

input.error {
  border: 1px solid red !important;
}

.dashboard-login {
  >.container {
    @include respondto(desktopSmall) {
      width: calc(100% - 3rem);
    }

    @include respondto(maxTablet) {
      width: calc(100% - 3rem);
    }

  }

  .button-block {
    @include respondto(maxTablet) {
      text-align: center;
      margin-top: 5rem;
    }
  }
}

.title-dashboard {
  h2 {
    font-size: 5.2rem;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 5.8rem;
    color: #59595B;
    font-family: 'Poppins', sans-serif!important;

    @include respondto(phone) {
      font-size: 2.4rem;
      line-height: 2.1rem;

    }
  }

  p {
    font-family: 'Poppins', sans-serif!important;
    color: #59595B;
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 2.9rem;

    @include respondto(phone) {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }
}

.login-block {
  max-width: 1228px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20rem;
  padding: 5.2rem 5.1rem 4.8rem 6.7rem;
  background-color: $grey-light3;


  // .login-block__remember
  &__remember {
    width: 50%;
    position: relative;
    cursor: pointer;

    span {
      width: calc(100% - 1.9rem);
      padding-left: 1.9rem;
      font-size: 1.2rem;
      letter-spacing: 0.26px;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
      line-height: 14px;
    }

    &::before {
      content: '';
      position: absolute;
      border: 1px solid $grey-dark16;
      background-color: $white;
      width: 1.2rem;
      height: 1.2rem;
      left: 0;
      top: 0;
      border-radius: 50%;
    }

    &.active {
      &::before {
        background-color: $black;
        border-color: $black;
      }
    }
  }

  @include respondto(maxTablet) {
    padding: 5.2rem 3rem 4.8rem 3rem;
    margin-bottom: 0;
    flex-direction: column;
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
  }

  input {
    width: 100%;
    color: #757575;
    font-family: 'Poppins', sans-serif!important;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.34px;
    line-height: 1.9rem;
  }

  .sign-up-info {
    width: calc(65% - 11.1rem);
    margin-left: auto;

    @include respondto(maxTablet) {
      width: 100%;
      margin-left: auto;
      margin-left: auto;
    }
  }

  .img-wrapper {
    min-height: 56.9rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.46);
      z-index: 1;
    }

    img {
      z-index: 0;

    }
  }

  .content-info {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 22rem 10rem 9.4rem 10rem;
    color: $white;

    @include respondto(maxTablet) {
      padding: 22rem 5rem 9.4rem 5rem;
    }

    @include respondto(phone) {
      padding: 22.4rem 1.2rem 2.3rem 2.1rem;
    }

    p {
      color: $white;
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: 0.3px;
      line-height: 2.4rem;
      max-width: 39.6rem;
    }

    h3 {
      font-family: 'Poppins', sans-serif!important;
      font-size: 3rem;
      font-weight: 400;
      letter-spacing: 0.55px;
      line-height: 4.4rem;
    }

    ul {
      li {
        list-style: none;
        position: relative;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 2.5rem;
        margin-bottom: 1rem;
        padding-left: 2rem;

        &:before {
          content: '';
          width: 1.1rem;
          height: 1.1rem;
          position: absolute;
          line-height: 2.5rem;
          left: 0;
          top: 0.6rem;
          border-radius: 50%;
          border: 0.2rem solid $green-light1;
        }
      }
    }
  }

  .button-green {
    font-weight: 400;
    font-family: 'Poppins', sans-serif!important;
    padding: 1rem 4rem;
    background-color: $green-light9;
  }

  .button-blue-dark {
    background-color: $blue-dark8;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    font-family: 'Poppins', sans-serif!important;

    // padding: 1.1rem 4.34rem;
    &:hover {
      background-color: $blue-dark9;
    }
  }
}

.input-block {
  position: relative;

  i {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    font-size: 2rem;
    color: #BEBEBE;
  }
}

.login-form {
  width: 100%;
  background-color: $white;
  padding: 3.44rem 2.6rem 1.56rem 2.9rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;


  @include respondto(maxTablet) {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  h2 {
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 0.56px;
    line-height: 3rem;
    color: $blue-dark8;
  }

  p {
    font-family: 'Poppins', sans-serif!important;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    letter-spacing: 0.3px;
    color: $grey-light6;
  }

  .form-block {
    label {
      font-family: 'Poppins', sans-serif!important;
      font-size: 1.8rem;
      font-weight: 400;
      letter-spacing: 0.39px;
      color: $grey-light6;
    }

    input {
      max-width: 100%;
    }
  }

  .link-block {
    width: 50%;

    a {
      font-size: 1.5rem;
      font-weight: 300;
      letter-spacing: 0.26px;
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }

  .round-checkbox-login {
    width: 50%;
    position: relative;

    input[type="checkbox"] {
      display: none;

      &:checked+label:after {
        background-color: $blue-dark8;
      }
    }

    label {
      width: 100%;

      &:after {
        content: '';
        position: absolute;
        border: 1px solid #BEBEBE;
        width: 1.2rem;
        height: 1.2rem;
        left: 0;
        top: 0;
        border-radius: 50%;
      }

    }

    span {
      width: calc(100% - 1.9rem);
      padding-left: 1.9rem;
      font-size: 1.2rem;
      letter-spacing: 0.26px;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
      line-height: 14px;
    }
  }
}

p.success-label {
  color: green;
  font-weight: bold;
  font-size: 1.8rem;
}

.item__select {
  background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid $grey-dark15;
  border-radius: .4rem;
  color: $grey-dark7;
  cursor: pointer;
  font-family: 'Poppins', sans-serif!important;
  font-weight: 400;
  height: 4.2rem;
  width: 100%;
  font-size: 1.8rem;
  position: relative;

  .selected {
    padding: 8px;
  }

  .selectItems {
    max-height: 40rem;
    overflow-y: auto;
    padding-left: 2.6rem;
  }

  .selectItem {
    background: $white;
    margin-left: -2.6rem;
    border: 1px solid $grey-dark15;
    padding: 8px;
    position: relative;
    z-index: 99;
  }

  &:after {
    content: '\F078';
    color: $grey-light2;
    font-family: "FontAwesome";
    font-size: 0.6rem;
    position: absolute;
    top: 50%;
    pointer-events: none;
    right: 2rem;
    transform: translate(50%, -50%);
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-bottom-0_3 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 0.3rem;
  }
}

.margin-bottom-1_5 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 1.5rem;
  }
}

.margin-bottom-1_7 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 1.7rem;
  }
}

.margin-bottom-1_8 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 1.8rem;
  }
}

.margin-bottom-1-8-xs {
  @include respondto(phone) {
    margin-bottom: 1.8rem;
  }
}

.margin-bottom-1_3-xs {
  @include respondto(phone) {
    margin-bottom: 1.3rem;
  }
}

.margin-bottom-1_9 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 1.9rem;
  }

}

.margin-bottom-2_3 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.3rem;
  }

}

.margin-bottom-2_4 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.4rem;
  }

}

.margin-bottom-2_5 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.5rem;
  }
}

.margin-bottom-2_6 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.6rem;
  }
}

.margin-bottom-2_8 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.8rem;
  }
}

.margin-bottom-2_9 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 2.9rem;
  }
}

.margin-bottom-3_2 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.2rem;
  }
}

.margin-bottom-3_3 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.3rem;
  }
}

.margin-bottom-3_4 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.4rem;
  }
}

.margin-bottom-3_5 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.5rem;
  }
}

.margin-bottom-3_6 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.6rem;
  }

}

.margin-bottom-3_7 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 3.7rem;
  }

}

.margin-bottom-4_4 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 4.4rem;
  }

}

.margin-bottom-4_8 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 4.8rem;
  }
}

.margin-bottom-6_8 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 6.8rem;
  }
}

.margin-bottom-11_4 {
  @include respondto(desktopAndTablet) {
    margin-bottom: 11.4rem;
  }
}



.content-component {
  font-size: 1.5rem;

  @include respondto(maxTablet) {
    padding: 0;
  }

  .container {
    padding: 0;

    .dashboard-block__slider {
      padding: 2rem;
    }
  }
}

// .select-dashboard {
//   width: 100%;
//   position: relative;
//   margin-left: auto;

//   @include respondto(phone) {
//     width: 100%;
//   }

//   select {
//     background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
//     border: 1px solid $grey-dark15;
//     border-radius: .4rem;
//     color: #354052;
//     cursor: pointer;
//     font-family: 'Poppins', sans-serif!important;
//     font-weight: 400;
//     height: auto;
//     padding: 1rem 1.5rem;
//     width: auto;
//     font-size: 1.4rem;

//     &::-ms-expand {
//       display: none;
//     }
//   }

//   &:after {
//     content: '\f078';
//     color: $grey-light2;
//     font-family: $font-awesome;
//     font-size: 0.6rem;
//     position: absolute;
//     top: 50%;
//     pointer-events: none;
//     right: 0;
//     transform: translate(50%, -50%);
//   }
// }

.border-with-text {
  width: 100%;
  height: 10px;
  margin: 20px 0;
  border-width: 2px 0 0 0;
  border-color: #D8D8D8;
  position: relative;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    display: block;
    width: auto;
    background-color: #F4F4F4;
    margin: 0 auto;
    top: -14px;
    padding: 1px 10px;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif!important;
  }
}

.dashboard-block {
  width: calc(50% - 2.2rem);
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1.2rem;
  min-height: 52rem;
  border: 1px solid rgba($grey-light4, 0.4);
  border-radius: 0.4rem;
  background-color: #FFFFFF;
  float: left;
  margin-bottom: 3.3rem;

  @include respondto(phone) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .button {
    border: 1px solid $grey-light4;
    border-radius: 6px;
    display: inline-block;
    padding: 1.1rem 1.7rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: $grey-dark6;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;

    &:hover {
      color: $blue-light2;
      border-color: $blue-light2;
    }
  }
}

//.table-row {
//  border: 1px solid #E6EAEE;
//  border-radius: 4px;
//  background-color: $white;
//  table {
//    font-size: 1.4rem;
//    width: 100%;
//    img {
//
//      max-width: 70px;
//    }
//  }
//  p {
//    font-size: 1.4rem;
//    font-weight: 300;
//    line-height: 1.9rem;
//    max-width: 24.2rem;
//  }
//  .button-table {
//    display: inline-block;
//    border: 1px solid #979797;
//    border-radius: 6px;
//    color: #6D7278;
//    padding: 0.5rem;
//    min-width: 9.1rem;
//    font-size: 1rem;
//    text-align: center;
//    line-height: 1.2rem;
//    &:hover {
//      color: $blue-dark2;
//      border-color: $blue-dark2;
//    }
//  }
//  .table > thead > tr > th {
//    font-weight: 300;
//    color: $grey-dark6;
//    font-size: 1.4rem;
//    line-height: 1.7rem;
//
//  }
//}

.co-op-advertising-transactions {
  padding: 1rem 2rem 1rem 1rem;

  @include respondto(phone) {
    padding: 1rem;
  }

  h4 {
    color: $black;
    font-size: 1.6rem;
    line-height: 3rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respondto(phone) {
      text-align: center
    }
  }

  .top-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include respondto(phone) {
      justify-content: space-around
    }

    a {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }

  .bottom-content {

    .button,
    a {
      border: 1px solid $grey-light4;
      border-radius: 6px;
      display: inline-block;
      padding: 1.1rem 1.7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $grey-dark6;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      margin-left: 9px;
      margin-bottom: 9px;

      &:hover {
        color: $blue-light2;
        border-color: $blue-light2;
      }
    }
  }

}

.block-advertising-transactions {

  &_blue,
  &_green {
    width: calc(50% - 9px);
    float: left;
    margin: 4.5px;
    border-radius: 0.4rem;
    color: $white;
    position: relative;

    @include respondto(phone) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      color: $white;
      opacity: 0.7;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-top: 3.2rem;
      margin-bottom: 1.8rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;

    }

    span {
      font-size: 4.8rem;
      font-weight: 300;
      line-height: 5.8rem;
    }

    .bordered-item {
      width: calc(100% - 2.5rem);
      height: 1.21px;
      background-color: rgba($white, 0.2);
      display: block;
      margin-top: 1.711rem;
      margin-bottom: 5.68rem;
      margin-left: 1.25rem;

    }
  }

  &_green {
    background-color: $green-light4;
  }

  &_blue {
    background: linear-gradient(0deg, $blue-light5 0%, $blue-light6 100%);
  }
}

//leads-report
.leads-report {
  padding: 0.3rem 1.7rem 0 1.7rem;

  .title {
    h4 {
      color: $black6;
      font-size: 1.6rem;
      line-height: 3rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;

    }
  }
}

//service area
.service-area-block {
  svg {
    width: 2.2rem;
    height: 2.6rem;
    //background: linear-gradient(135deg, #02AAB0 0%, #00CDAC 100%);
  }
}

.top-service-area {
  margin-bottom: 0.8rem;

  .left-top-service-area {
    width: 55%;

    h4 {
      color: $black4;
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }

    span {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 2.1rem;
    }

  }

  .right-top-service-area {
    width: 45%;
  }
}

//dashboard form
.dashboard-block {
  .title-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;

    h4 {
      color: $black3;
      font-size: 1.6rem;
      line-height: 1.6;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;

      @include respondto(phone) {
        width: 100%;
        text-align: center;
      }
    }

    // span {
    //   color: $grey-dark12;
    //   font-size: 1rem;
    //   font-weight: 300;
    //   line-height: 1.2rem;
    //   font-family: 'Poppins', sans-serif!important;

    //   @include respondto(phone) {
    //     width: 100%;
    //     text-align: center;
    //   }
    // }

    a {
      color: $blue-dark10;
      font-size: 1.5rem;
      line-height: 2.1rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      width: 40px;
      text-align: right;

      @include respondto(phone) {
        width: 100%;
      }

      &:hover {
        color: $blue-light5;
      }
    }
  }

  .top-service-area {
    .editServiceArea {
      position: absolute;
      top: -25px;
      right: 0;
    }

    .link-block {
      color: $blue-dark10;
      font-size: 1.5rem;
      line-height: 2.1rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      text-align: right;

      &.reset-btn {
        color: $grey-dark6;
      }

      &:hover {
        color: $blue-light5;
      }
    }
  }
}

.dashboard-form-block {
  svg {
    width: 20px;
    height: 20px;
  }

  .form-content {
    padding: 2.1rem 1rem 2.1rem 2.7rem;
    border: 0.5px solid $grey-light4;
    border-radius: 22px;
  }

  .form-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.9rem;

    h3 {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
      color: $blue-dark9;
    }

    span {
      color: #808080;
      font-size: 13px;
      font-weight: 400;
      font-family: 'Poppins', sans-serif!important;

      span.icon {
        svg {
          height: 20px;
          width: 20px;
          fill: $grey-dark6;
        }
      }
    }
  }

  .green-title {
    color: $green-light6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.7rem;

    span {
      font-size: 1.3rem;
      line-height: 2.1rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;

      i {
        font-size: 2.1rem;
      }
    }

    .icon {
      line-height: 1;
      margin-right: 0.5rem;
    }
  }

  textarea,
  input {
    border: 1px solid $grey-dark11;
    border-radius: 4px;
    color: $grey-dark12;
    font-size: 1.3rem;
    font-weight: 300;

    line-height: 1.4rem;

    &::placeholder {
      opacity: 0.59;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  label {
    font-size: 1.3rem;
    font-weight: 300;
    color: $grey-dark12;
    line-height: 2.1rem;
    font-family: 'Poppins', sans-serif!important;
  }

  input {
    width: calc(100% - 2.5rem);
    padding: 0.5rem;
  }

  textarea {
    padding: 0.5rem 0.5rem 0.5rem 0.9rem;
    margin-bottom: 1rem;
    max-height: 7.5rem;
  }

  form {
    //  display: flex;
    //  flex-direction: column;
    //  //max-width: 46.7rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }

  .top-form {
    display: block;
    margin-right: auto;
  }

  .bottom-form {
    width: 100%;
    display: block;
  }

  .textarea-lg div {
    color: #808080;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }

  .input-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .input-phone {
    // width: 40%;
    // float: left;

    a,
    p {
      color: #196ba2;
      font-size: 1.4rem;
      font-weight: 700;
    }
  }

  .input-email {
    // width: 60%;
    // float: left;

    a,
    p {
      color: #196ba2;
      font-size: 1.4rem;
      font-weight: 700;
    }
  }

  button {
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2rem;
    padding: 0.1rem 0;
    font-family: 'Poppins', sans-serif!important;

    &.save-btn {
      color: $blue-light2;

      &:hover {
        color: $blue-dark9;
      }
    }

    &.reset-btn {
      color: $grey-dark6;

      &:hover {
        color: $black;
      }
    }

  }

  .bottom-form {
    span {
      font-size: 1rem;
      color: $grey-dark12;
      font-weight: 300;
      line-height: 1.2rem;
    }
  }

}

.coupons-info-block {
  border: 0.5px solid $grey-light4;
  border-radius: 2.2rem;
  padding: 1.825rem 3.325rem 1.825rem 3.025rem;

  p {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.4rem;
    color: $grey-dark12;
    font-family: 'Poppins', sans-serif!important;
  }
}

.title-dashboard {
  @include respondto(phone) {
    padding: 0 1rem;
    flex-direction: column;
  }

  .title {
    h1 {
      margin-bottom: 41px;

      @include respondto(phone) {
        margin-bottom: 21px;
      }
    }
  }
}

.dashboard-block {
  .select-dashboard {
    width: 100%;
    position: relative;
    margin-left: auto;

    @include respondto(phone) {
      width: 100%;
    }

    select,
    .select-block {
      background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
      border: 1px solid $grey-dark15;
      border-radius: .4rem;
      color: #354052;
      cursor: pointer;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
      height: 4.2rem;
      padding: 1rem 1.5rem;
      width: 100%;
      font-size: 1.4rem;

      &:hover {
        border: 1px solid $grey-dark15;
      }

      &::-ms-expand {
        display: none;
      }
    }

    .select-block {
      div {
        padding: 0 0 12px 0;
      }

      .select-miles {
        background: white;
        margin-left: -1.6rem;
        margin-right: -1.6rem;
        border: 1px solid $grey-dark15;
        padding: 3px 0 3px 1.6rem;
        position: relative;
        z-index: 99;
      }
    }

    &:after {
      content: '\f078';
      color: $grey-light2;
      font-family: $font-awesome;
      font-size: 0.6rem;
      position: absolute;
      top: 50%;
      pointer-events: none;
      right: 3rem;
      transform: translate(50%, -50%);
    }
  }
}

.border-with-text {
  width: 100%;
  height: 10px;
  margin: 20px 0;
  border-width: 2px 0 0 0;
  border-color: rgba($grey-dark12, .4);
  position: relative;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: absolute;
    display: block;
    width: auto;
    background-color: #F4F4F4;
    color: $grey-dark6;
    margin: 0 auto;
    top: -14px;
    padding: 1px 10px;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }
}

.dashboard-block {
  width: calc(50% - 2.2rem);
  padding: 1.5rem;
  min-height: 46rem;
  border: 1px solid rgba($grey-dark9, 0.4);
  border-radius: 0.6rem;
  background-color: $white;
  margin-bottom: 3.3rem;

  @include respondto(phone) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border: none;

    &.border-bottomMobile {
      border-bottom: 2px solid rgba($grey-dark12, .4);
      border-radius: 0;
      margin-bottom: 2rem;
    }
  }

  .border-top {
    border-top: 2px solid rgba($grey-dark12, .4);
  }

  .title-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.7rem;

    h4 {
      color: $black3;
      font-size: 1.6rem;
      line-height: 1.6;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }

    span {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 2.1rem;
    }

    // span {
    //   color: $grey-dark12;
    //   font-size: 1rem;
    //   font-weight: 400;
    //   line-height: 1.2rem;
    //       font-family: 'Poppins', sans-serif!important;
    // }

    a {
      color: $blue-dark10;
      &:hover {
        color: $blue-light5;
      }
    }
  }

  .right-top-service-area {
    .button {
      border: none;
      background-color: $green-light7;
      color: $green-light8;
      display: inline-block;
      font-size: 1.6rem;
      line-height: 1.8rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      text-align: center;
      height: 34px;
      padding: 0.8rem 0;
      width: 130px;
      cursor: pointer;
      transition: background-color .3s, color .3s;

      &:nth-child(2) {
        border-radius: 0 9px 9px 0;
      }

      &:nth-child(1) {
        border-radius: 9px 0 0 9px;
      }

      &:hover,
      &.active {
        background-color: $green-light8;
        color: $white;
      }
    }
  }

  .dashboard-block__mobile {
    @include respondto(phone) {
      padding: 2.1rem 1rem;
      border: 0.5px solid rgba($grey-light4, .4);
      border-radius: 22px
    }
  }

  .media-slider-dotsFalse {

    &,
    .content-wrapper {
      height: 40rem;
      outline: none;

      @include respondto(phone) {
        height: 64rem;
      }
    }

    .img-wrapper {
      height: 30rem;
      width: 42rem;
    }

    .text-block {
      margin-left: 2rem;
      width: 68rem;

      @include respondto(largeDesktop) {
        margin-left: 11rem;
      }

      @include respondto(phone) {
        margin-left: 0;
      }
    }

    .img-wrapper,
    .text-block {
      @include respondto(phone) {
        width: 100%;
      }
    }

    .slider-icon {
      margin-right: 2rem;

      @include respondto(phone) {
        margin: 0 0 1rem 0;
      }
    }

    .slick-next,
    .slick-prev {
      background-color: rgba(3, 3, 3, 0.3);
      border-radius: 100%;
      height: 4.6rem;
      width: 4.6rem;
      opacity: 0.63;
      position: absolute;
      top: 50%;
      z-index: 1;

      &:hover,
      &:focus {
        background-color: rgba(3, 3, 3, 0.4);
      }

      &:before {
        font-family: "FontAwesome";
        font-size: 3.5rem;
        opacity: 0.59;
      }
    }

    .slick-next {
      right: 1.4rem;

      &:before {
        content: "\f0da";
        margin-left: 0.5rem;
      }
    }

    .slick-prev {
      left: 1.4rem;

      &:before {
        content: "\f0d9";
        margin-right: 0.5rem;
      }
    }

    .slider-wrapper {
      @include respondto(onlyDesktop) {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      .slider-content {
        height: 100%;
        left: 50%;
        margin: 0 auto;
        max-width: 90rem;
        padding: 6rem 0 2rem 0;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        width: 100%;

        @include respondto(desktopSmall) {
          max-width: 70rem;
        }

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
          max-width: none;
          width: 90rem;
        }

        @include respondto(tablet) {
          max-width: 55rem;
        }

        @include respondto(phone) {
          padding: 0 .5rem;
          top: 40%;
        }
      }

      p {
        color: $black7;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;

        @include respondto(phone) {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      .title-item {
        font-family: 'Poppins', sans-serif!important;
        font-weight: 500;
        font-size: 1.6rem;

        @include respondto(tablet) {
          font-size: 3.2rem;
          line-height: 4.2rem;
        }

        @include respondto(phone) {
          font-size: 2.4rem;
          line-height: 3rem;
          margin-bottom: 0.5rem;
        }

        span {
          font-family: 'Poppins', sans-serif!important;
          font-weight: 700;
        }
      }
    }
  }

  &.dashboard-block__slider {
    width: calc(100% - 2rem);
  }

  .dashboard__error {
    margin-left: 4.5px;
    margin-right: 4.5px;
    padding: 0 22.5px;

    p {
      font-size: 1.4rem;
      line-height: 1.5;
      color: red;
      opacity: .7;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 700;
    }

    a {
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }
}


.co-op-advertising-transactions {
  padding: 1rem 2rem 1rem 1rem;

  @include respondto(phone) {
    padding: 1rem;
  }

  h4 {
    font-size: 1.6rem;
    line-height: 3rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;

  }

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respondto(phone) {
      text-align: center
    }
  }

  .top-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include respondto(phone) {
      justify-content: space-around;
      margin-bottom: 1rem;
    }

    a {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }
}

.bottom-content {
  @include respondto(phone) {
    margin-top: 1rem !important;
  }

  .button,
  a {
    border: 1px solid $grey-light4;
    border-radius: 6px;
    background-color: $white;
    display: inline-block;
    padding: 1.1rem 1.7rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: $grey-dark6;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;

    &:hover {
      color: $blue-light2;
      border-color: $blue-light2;
    }
  }
}

.block-advertising-transactions {

  &_blue,
  &_green {
    width: calc(50% - 9px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 4.5px;
    border-radius: 0.4rem;
    color: $white;
    position: relative;
    padding: 0 10px;

    @include respondto(maxTablet) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @include respondto(desktopSmall) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      color: $white;
      opacity: 0.7;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-top: 3.2rem;
      margin-bottom: 1.8rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 700;
    }

    .price {
      font-size: 4.8rem;
      font-weight: 300;
      line-height: 5.8rem;
      margin-bottom: 5.68rem;
      border-bottom: .5px solid rgba($white, 0.3);
    }

    .bordered-item {
      width: calc(100% - 2.5rem);
      height: 1.21px;
      border-color: rgba($white, 0.3);
      border-width: .5px;
      display: block;
      margin-top: 1.711rem;
      margin-bottom: 5.68rem;
      margin-left: 1.25rem;
    }
  }

  &_green {
    background-color: $green-light4;
  }

  &_blue {
    background: linear-gradient(0deg, $blue-light5 0%, $blue-light6 100%);
  }
}

//leads-report
.leads-report {
  &_value {
    color: $grey-dark6;
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-right: 5.9rem;
    }

    span {
      color: $grey-dark12;
      margin-top: 5px;
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }

  .title {
    h4 {
      color: $black6;
      font-size: 1.6rem;
      line-height: 3rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;

    }
  }
}

.monthlyLeads__title {
  h4 {
    color: $black6;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
  }

  .monthlyLeads__sub {
    color: $grey-dark3;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }
}

.montlyLeads__items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 0 1 auto;
  justify-content: space-between;

  .montlyLeads__item {
    border: 1px solid rgba($grey-light4, .4);
    border-radius: 6px;
    padding: 10px;
    flex-grow: 1;
    flex-basis: 48%;
    max-width: 48%;
    margin-bottom: 1.1rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 48px;
      width: 3px;
      left: 0;
      top: calc(50% - 24px);
    }

    &:nth-child(1) {
      &:before {
        background-color: $blue-light7;
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: $orange-light1;
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: $green-light3;
      }
    }

    &:nth-child(4) {
      &:before {
        background-color: $blue-light11;
      }
    }

    @include respondto(desktopSmallAndTablet) {
      flex-basis: 100%;
      max-width: 100%;
    }

    @include respondto(phoneSmall) {
      flex-basis: 100%;
      max-width: 100%;
    }

    .montlyLeads__itemTitle {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      color: $grey-dark3;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 30px;
    }

    .montlyLeads__dates {
      margin: 30px 0;
      display: flex;
      justify-content: space-around;
      text-align: center;

      .montlyLeads__ytd,
      .montlyLeads__value {
        font-family: 'Poppins', sans-serif!important;
        font-weight: 500;
        color: $grey-dark3;
        font-size: 1.2rem;
        line-height: 15px;

        .montlyLeads__number {
          color: $grey-dark5;
          font-family: 'Poppins', sans-serif!important;
          font-weight: 400;
        }
      }

      .montlyLeads__iconDisplays {
        height: 31px;
        width: 31px;
        fill: $blue-light7;
      }

      .montlyLeads__iconPhone {
        height: 41px;
        width: 41px;
        fill: $orange-light1;
      }

      .montlyLeads__iconEmail {
        height: 27px;
        width: 35px;
        fill: $green-light3;
      }

      .montlyLeads__iconClick {
        height: 29px;
        width: 29px;
        fill: $blue-light11;
      }
    }
  }
}

//service area
.service-area-block {
  display: flex;
  flex-direction: column;
  height: 100%;

  svg,
  .icon {
    width: 2.2rem;
    height: 2.6rem;
  }

  .top-service-area {
    margin-bottom: 0.8rem;
    flex: 1 0 auto;
    flex-direction: inherit;

    .top-service-area__title {
      margin-bottom: 10px;
      position: relative;

      @include respondto(maxTablet) {
        flex-direction: column;
        padding: 0;

        .right-top-service-area {
          margin: 20px 0;
        }
      }

      .left-top-service-area {
        width: 55%;

        @include respondto(maxTablet) {
          width: 100%;
        }

        h4 {
          color: $black4;
          font-size: 1.6rem;
          line-height: 1.8rem;
          font-family: 'Poppins', sans-serif!important;
          font-weight: 500;
        }

        span {
          font-size: 1.3rem;
          font-weight: 300;
          line-height: 2.1rem;
        }

      }

      .right-top-service-area {
        width: 45%;
        display: flex;

        @include respondto(maxTablet) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .bottom-service-area {
    flex: 0 0 auto;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    @include respondto(desktopSmall) {
      flex-wrap: wrap;
    }

    @include respondto(maxTablet) {
      margin-top: 18px;
      flex-wrap: wrap;
    }

    .left-block {
      display: flex;
      align-items: center;
      flex-grow: 0;
      margin-right: 5px;

      .icon {
        margin-right: 15px;
      }

      .content {
        color: $grey-dark8;

        h4 {
          font-size: 16px;
          margin-bottom: 5px;
          font-family: 'Poppins', sans-serif!important;
          font-weight: 700;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.4;
          font-family: 'Poppins', sans-serif!important;
          font-weight: 300;
          color: $grey-dark8;

          @include respondto(largeDesktop) {
            max-width: 170px;
          }
        }
      }

      @include respondto(desktopSmall) {
        margin-bottom: 10px;
      }

      @include respondto(maxTablet) {
        flex-grow: 1;
        margin-bottom: 10px;
      }
    }

    .right-block {
      width: 45%;

      @include respondto(desktopSmall) {
        width: 100%;
      }

      @include respondto(maxTablet) {
        width: 100%;
      }

      .setRadiusText {
        font-size: 1rem;
        color: $grey-dark11;
        font-weight: 300;
        line-height: 12px;
      }
    }
  }
}


.dashboard-form-block {
  svg {
    width: 20px;
    height: 20px;
  }

  .form-content,
  .coupons-info-block {
    padding: 2.1rem 1rem 2.1rem 2.7rem;
    border: 0.5px solid rgba($grey-light4, .4);
    border-radius: 22px;

    @include respondto(phone) {
      padding: 1rem;
    }
  }

  .coupons-info-block {
    @include respondto(phone) {
      border: none;
      padding: 0;
    }
  }

  .form-content__title {
    display: flex;
    justify-content: space-between;

    @include respondto(phone) {
      display: block;
    }

    .form-title {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      order: 1;

      h3 {
        font-family: 'Poppins', sans-serif!important;
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: $blue-dark9;
        margin-bottom: 0.9rem;
        font-weight: 700;

        @include respondto(phone) {
          font-size: 1.6rem;
          margin-bottom: 0;
        }
      }

      .green-title {
        color: $green-light6;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0.7rem;
        font-size: 1.3rem;
        line-height: 2.1rem;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 700;

        svg {
          font-size: 1.6rem;
          margin-right: .5rem;

          @include respondto(phone) {
            font-size: 1.3rem;
          }
        }

        .icon {
          line-height: 1;
          margin-right: 0.5rem;
        }
      }
    }

    // .form-title__geolocation {
    //   color: #808080;
    //   font-size: 1.3rem;
    //   font-weight: 400;
    //   font-family: 'Poppins', sans-serif!important;
    //   display: flex;
    //   flex-wrap: nowrap;
    //   order: 2;

    //   span {
    //     padding-left: 0.3rem;
    //   }

    //   span.icon {
    //     padding: 0;

    //     svg {
    //       height: 20px;
    //       width: 20px;
    //       fill: $grey-dark6;
    //     }
    //   }

    //   @include respondto(phone) {
    //     font-size: 1rem;
    //     justify-content: flex-end;
    //     margin-bottom: 15px;

    //     span.icon {
    //       svg {
    //         height: 10px;
    //         width: 10px;
    //       }
    //     }
    //   }
    // }
  }

  textarea,
  input {
    border: 1px solid $grey-dark11;
    border-radius: 4px;
    color: $grey-dark12;
    font-size: 1.3rem;
    font-weight: 300;

    line-height: 1.4rem;

    &::placeholder {
      opacity: 0.59;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  label {
    font-size: 1.3rem;
    color: $grey-dark12;
    line-height: 2.1rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }

  input {
    width: calc(100% - 2.5rem);
    padding: 0.5rem;
  }

  textarea {
    padding: 0.5rem 0.5rem 0.5rem 0.9rem;
    margin-bottom: 1rem;
    max-height: 7.5rem;
  }

  form {
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }

  .top-form {
    display: block;
    max-width: 100%;
    margin-right: auto;
  }

  .bottom-form {
    width: 100%;
    display: block;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  .input-phone,
  .input-email {
    display: flex;
    flex-wrap: nowrap;
    width: 45%;
    margin-right: 1rem;

    @include respondto(maxTablet) {
      margin-bottom: 1rem;
      margin-right: 0;
      width: 100%;
    }

    p {
      line-height: 1.5;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      width: 2rem;

      svg {
        fill: $grey-dark6;
        color: $grey-dark6;
      }

      i {
        color: $grey-dark6;
      }
    }
  }

  button {
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2rem;
    padding: 0.1rem 0;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;

    &.save-btn {
      color: $blue-light2;

      &:hover {
        color: $blue-dark9;
      }
    }

    &.reset-btn {
      color: $grey-dark6;

      &:hover {
        color: $black;
      }
    }

  }

  .bottom-form {
    span {
      font-size: 1rem;
      color: $grey-dark12;
      font-weight: 300;
      line-height: 1.2rem;
    }
  }

}

.coupons-title {
  margin-bottom: 0.775rem;

  h4 {
    width: 50%;
    color: $black3;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3rem;

    @include respondto(phone) {
      width: 100%;
    }
  }

  a {
    color: $blue-dark10;
    font-size: 1.5rem;
    line-height: 2.1rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
    padding-right: 1rem;

    &:hover {
      color: $blue-light5;
    }
  }
}

.coupons-info-block {
  p {
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: $grey-dark12;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }
}

//sales self reporting
.sales-self-reporting {

  &_blue,
  &_green,
  &_yellow,
  &_dark-blue {
    position: relative;
    width: calc(25% - 0.875rem);
    border: 1px solid rgba($grey-dark9, .4);
    margin-bottom: 4.75rem;
    border-radius: 0 0 6px 6px;
    border-top: none;
    flex-basis: 23%;
    max-width: 23%;

    @include respondto(desktopSmallAndTablet) {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 2rem;
    }

    @include respondto(phoneXS) {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 2rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -1px;
      right: -1px;
      height: 4px;
    }

    p {
      margin-top: 3.4rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $grey-dark3;
      margin-bottom: .7rem;
      padding: 0 5px;
    }

    span {
      font-size: 3.6rem;
      line-height: 4.3rem;
      color: $grey-dark5;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      margin-bottom: 6.15rem;
      display: block;

    }
  }

  &_blue {
    &:before {
      background-color: $blue-light7;
    }
  }

  &_green {
    &:before {
      background-color: $green-light5;
    }
  }

  &_yellow {
    &:before {
      background-color: $orange1;
    }
  }

  &_dark-blue {
    &:before {
      background-color: $blue-light9;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 0 1 auto;
    justify-content: space-around;

    @include respondto(phone) {
      flex-direction: column;
      align-items: center;
    }
  }

  .button {
    min-width: 20rem;
  }
}

.dashboard-block__slider,
.sales-self-reporting {
  .title {
    margin-top: 1.2rem;
    padding-left: 0;
    margin-bottom: 3rem;

    @include respondto(phone) {
      text-align: center;
      padding-left: 0;
    }

    h4 {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      color: $black;
      font-size: 1.6rem;
      line-height: 3rem;
    }

    span {
      color: $grey-dark12;
      font-size: 1.3rem;
      line-height: 1.3rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
    }
  }
}

//Create a coupon
.create-coupon-block {
  .title {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 15px;
  }

  p {
    color: $grey-dark5;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    opacity: 0.58;
    max-width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;

  }

  strong {
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    color: $grey-dark5;
    font-size: 4.8rem;
    line-height: 1rem;
    position: absolute;
  }

  .quotation-mark-left {
    left: 0;
    top: 2rem;
  }

  .quotation-mark-right {
    right: -1rem;
    bottom: 0;
  }

  a {
    color: $blue-light2;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;

    &:hover {
      color: $blue-dark9;
    }
  }
}

//Literature
.literature-block {
  padding: 0.4rem 0 1.7rem 0;

  h4 {
    color: $black;
    font-size: 1.6rem;
    line-height: 3rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
  }

  .top-title {
    position: relative;

    @include respondto(phone) {
      justify-content: space-around;
      margin-bottom: 1rem;

      h4 {
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    &:before {
      content: '';
      width: calc(100% + 2.4rem);
      height: 1px;
      background-color: $grey-dark13;
      left: -1.2rem;
      right: -1.2rem;
      position: absolute;
      bottom: 0;

      @include respondto(maxTablet) {
        top: 0;
      }
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include respondto(phone) {
      flex-direction: column;
    }
  }
}


//sales self reporting
.sales-self-reporting {

  .title {
    margin-top: 1.2rem;
    padding-left: 1.4rem;
    margin-bottom: 3rem;

    @include respondto(phone) {
      text-align: center;
      padding-left: 0;
    }

    h4 {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
      color: $black6;
      font-size: 1.6rem;
      line-height: 3rem;
    }

    span {
      color: $grey-dark3;
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 1.3rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .button {
    min-width: 20rem;
  }
}


//Create a coupon
.create-coupon-block {
  .title {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;

  }

  p {
    color: $grey-dark5;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.9rem;
    opacity: 0.58;
    max-width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;

  }

  strong {
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    color: $grey-dark5;
    font-size: 4.8rem;
    line-height: 1rem;
    position: absolute;
  }

  .quotation-mark-left {
    left: 0;
    top: 2rem;
  }

  .quotation-mark-right {
    right: -1rem;
    bottom: 0;
  }

  a {
    color: $blue-light2;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.3rem;

    &:hover {
      color: $blue-dark9;
    }
  }
}

//Literature
.literature-block {
  padding: 0.4rem 0 1.7rem 0;

  h4 {
    color: $black;
    font-size: 1.6rem;
    line-height: 3rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
  }

  .top-title {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 1.3rem;
      font-weight: 300;
      line-height: 1.6rem;
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include respondto(phone) {
      flex-direction: column;
    }
  }
}

.block-literature {

  &_blue,
  &_green {
    border-radius: 0 0 6px 6px;
    background-color: $white;
    position: relative;
    border: 1px solid rgba($grey-dark9, .4);
    margin: 0.7rem;
    border-top: none;
    flex-basis: 40%;

    @include respondto(desktopSmallAndTablet) {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 2rem;
    }

    @include respondto(phoneXS) {
      flex-basis: 100%;
      max-width: 100%;
      margin-bottom: 2rem;
    }

    @include respondto(phone) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -1px;
      right: -1px;
      height: 4px;
    }

    p {
      margin-top: 3rem;
      color: #666666;
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-bottom: 2rem;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }

    span {
      font-size: 4.8rem;
      line-height: 5.6rem;
      margin-bottom: 6rem;
      display: block;
      color: $grey-dark5;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 500;
    }
  }

  &_blue {
    &:before {
      background-color: $blue-light10;
    }
  }

  &_green {
    &:before {
      background-color: $green-light5;
    }
  }



}

////
.float-left {
  float: left;
}

.margin-none {
  margin: 0;
}

.order-6 {
  order: 6;
}

.dashboard-table {
  @include respondto(phone) {

    padding-left: 0;
    padding-right: 0;
  }
}

.column-padding {
  padding: 2rem;
  width: 100%;
  border-bottom: 1px solid $grey-light5;

  @include respondto(phone) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 2.4rem 0;

  }
}

.margin-sm {
  margin-top: 5px;
  margin-bottom: 5px;

  @include respondto(phone) {
    margin-bottom: 0.1rem;
  }
}

.scroll-block {
  height: 40.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  @include respondto(phone) {
    height: auto;
  }

  @include respondto(phone) {
    overflow-y: initial;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    background-color: #F7F7F7;

    @include respondto(phone) {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #F7F7F7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .01);
    background-color: #E7E7E7;
  }
}

/////
.associates-table-content {
  padding: 0;
  width: 100%;

  @include respondto(phone) {
    padding-bottom: 1rem;

    .padding-none {
      padding: 0;
    }
  }

  .description-item {
    margin: 0 auto;
    max-width: 62%;
    color: $grey-dark4;
    font-weight: 300;
    font-family: 'Poppins', sans-serif!important;
  }

}

.block-sync-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 46rem;

  .block-sync {
    font-size: 30px;
    color: rgba($grey-light4, 0.4);
  }
}

.fadeTransition-enter {
  opacity: 0;
}

.fadeTransition-enter.fadeTransition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fadeTransition-leave {
  opacity: 1;
}

.fadeTransition-leave.fadeTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.dashboardHistory {
  border-top: 1.5rem solid $green-light10;
  padding-bottom: 8rem;

  h2 {
    color: $blue-dark9;
    font-size: 4.8rem;
    font-weight: 600;
    padding-top: 4rem;

    @include respondto(phone) {
      line-height: 1;
      padding-top: 2rem;
      font-size: 2.4rem;
      font-weight: 400;
    }
  }

  .dashboardHistory__title {
    color: $grey-dark17;
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.5;
    padding: 2.5rem 0;

    @include respondto(phone) {
      font-size: 1.4rem;
      line-height: 1.8;
      padding: 1.5rem 0;
    }
  }

  .dashboardHistory__addition {
    font-size: 2.2rem;
    line-height: 1.5;
    color: $black;

    @include respondto(phone) {
      font-size: 1.4rem;
      font-weight: 300;
    }

    a {
      font-weight: 400;
      color: $blue-light2;
      font-size: 2.2rem;

      @include respondto(phone) {
        font-weight: 300;
        font-size: 1.4rem;
      }
    }
  }

  .dashboardHistory__table {
    .table-with-scroll {
      text-align: center;

      @include respondto(phone) {
        border: 1px solid $grey-dark10;
        border-radius: 4px;
        padding-bottom: 0;
        border-bottom: none;
      }

      .header-table {
        padding: 0;
        border: none;

        p {
          font-size: 1.8rem;
          line-height: 1.3;
        }
      }

      .block-item {
        padding: 2rem;
        border-right: 1px solid $grey-light5;

        &_title {
          display: none;

          @include respondto(phone) {
            display: block;

            &_alignLeft {
              text-align: left;
            }
          }
        }

        div {
          @include respondto(phone) {
            line-height: 1.5;
            font-size: 1.4rem;
            padding: 2rem 1rem;
            border-bottom: 1px solid $grey-dark10;
            min-height: 6.8rem;
          }
        }

        &:last-child {
          border: none;
        }
      }

      .layout-row {
        border-bottom: 1px solid $grey-light5;

        @include respondto(phone) {
          border: none;
        }

        .value-sm-item,
        .actions-item {
          padding: 2rem;
        }

        .value-sm-item {
          // border-right: 1px solid $grey-light5;
          border-left: 1px solid $grey-light5;
        }
      }

      .value-sm-item {
        width: 55%;
        margin-left: -1px;
      }

      .details-item {
        width: 55%;
      }

      .first-column,
      .date-item {
        width: 45%;
      }

      .date-item {
        font-size: 2.2rem;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 500;
      }

      .date-item,
      .details-item,
      .total-item {
        color: $grey-dark8;
      }

      .details-item,
      .total-item {
        font-size: 2.2rem;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 400;
      }

      .actions-item,
      .total-item {
        width: 15%
      }

      .dashboardHistory__nameElem,
      .dashboardHistory__item {
        width: 50%;
        text-align: left;
      }

      @include respondto(phone) {
        .block-item {
          font-size: 1.4rem;
          line-height: 3rem;
          color: $black5;
          border: none;
          padding-top: 2rem 0;

          &:first-child {
            border-right: none;
            border-bottom: 1px solid $grey-dark10;
            padding: 2rem 1rem;
          }
        }

        .date-item {
          font-weight: 700;
          align-items: flex-start;
        }

        .details-item,
        .total-item {
          font-weight: 300;
        }

        .date-item,
        .details-item,
        .total-item {
          width: 100%;
          padding: 0;
        }

        .dashboardHistory__nameElem,
        .dashboardHistory__item {
          padding-bottom: 1rem;
        }

        .dashboardHistory__item {
          text-align: right;
        }
      }
    }
  }

  .dashboardHistory__transactionsDate,
  .dashboardHistory__transactionsDetails {
    width: 50%;

    .actions-item {
      width: 100% !important;
    }

    .value-sm-item,
    .actions-item {
      border-bottom: 1px solid $grey-dark10;
    }

    p {
      color: $grey-dark;
      font-size: 1.8rem;

      @include respondto(phone) {
        font-size: 1.4rem;
      }
    }
  }

  .dashboardHistory__transactionsDate {
    border-right: 1px solid $grey-light5;

    @include respondto(phone) {
      width: 100%;
      border-bottom: 1px solid $grey-light5;

      .value-sm-item {
        margin: 0;
      }
    }
  }

  .dashboardHistory__transactionsItems {
    padding: 2rem;
  }

  .dashboardHistory__notes {
    color: $black;
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 32px;

    @include respondto(phone) {
      font-size: 1.4rem;
    }
  }

  .dashboardHistory__transactions {

    .table-with-scroll {
      text-align: center;

      .header-table {
        padding: 0;
        border: none;

        p {
          font-size: 1.8rem;
          color: $grey-dark;
        }
      }

      .scroll-block {
        .block_margin {
          @include respondto(phone) {
            margin: 0 0 2rem;
            border-bottom: none;

            .block-item {
              padding: 0;
            }
          }
        }
      }

      .block-item {
        padding: 2rem;
        border-right: 1px solid $grey-light5;

        &_title {
          display: none;

          @include respondto(phone) {
            display: block;
            width: 100%;
            padding: 2rem 0;
            border-bottom: 1px solid $grey-light5;
          }
        }

        &:last-child {
          border: none;
        }
      }

      .layout-row {
        border-bottom: 1px solid $grey-light5;

        @include respondto(phone) {
          border: 1px solid $grey-light5;
          border-radius: 4px;
        }

        .value-sm-item,
        .actions-item {
          padding: 2rem;
        }
      }

      .value-sm-item,
      .date-item {
        width: 100%;
      }

      .value-sm-item {
        border-right: 1px solid $grey-light5
      }

      .actions-item,
      .details-item {
        width: 60%;
      }

      .dashboardHistory__nameElem,
      .dashboardHistory__item {
        width: 50%;
        text-align: left;
      }

      @include respondto(phone) {
        .block-item {
          font-size: 1.4rem;
          font-weight: 300;
          line-height: 1.8rem;
          color: $black5;
          padding: 2rem 0;

          &:first-child {
            border-right: none;
          }

          span {
            padding: 2rem 0;
            border-bottom: 1px solid $grey-light5;
            width: 100%;
          }
        }

        .date-item,
        .details-item {
          width: 100%;
        }

        .dashboardHistory__nameElem,
        .dashboardHistory__item {
          padding-bottom: 1rem;
          line-height: 1.5;
        }

        .dashboardHistory__item {
          text-align: right;
        }
      }
    }
  }
}

.h-100 {
  height: 100%;
}

.font-italic {
  font-style: italic;
}

.flex-column {
  flex-direction: column;
}

.flex-column-ld {
  @include respondto(maxTablet) {
    flex-direction: column;
  }
}

.margin-right-0--ld {
  @include respondto(maxTablet) {
    margin-right: 0 !important
  }
}

.body-overflow {
  overflow: hidden;
}

.background-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 8;
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.background-wrapper2 {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 8;
}

.popup-text {
  background: white;
  width: 50%;
  position: relative;
  border: 1px solid #5e6977;
  padding: 20px;
  padding-right: 40px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .46);
  z-index: 12;
  margin: auto;

  @include respondto(phone) {
    width: 90%;
    top: 80px;
    bottom: 0;
  }
}

.popupText-close {
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    fill: #656565;
  }
}

.overlay-menu {
  background: rgba(0,61,165,.96)!important;
}

footer {
  background: $blue-dark8;
}