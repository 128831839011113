.form-block {
  label {
    line-height: 2.6rem;
    margin: 0;
    width: 19rem;
  }

  input,
  textarea {
    background-color: $grey-dark13;
  }

  input {
    border-radius: 4px;
    height: 3.5rem;
    width: 48rem;

    &:hover,
    &:focus {
      border-radius: 4px;
    }

    @include respondto(phone) {
      width: 100%;
    }
  }
}