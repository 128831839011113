.blog-section {
  margin-bottom: 11.3rem;

  @include respondto(phone) {
    margin-bottom: 6rem;
  }

  .articles-block {
    @include respondto(largeDesktop) {
      @include flex($justify: space-between);

      //to match design
      .col-sm-4 {
        @include flex($direction:column); //IE fix for min-height

        &:nth-child(2) {
          margin: 0 1.5rem;
        }
      }
    }
  }

  .fa-play {
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;
    font-size: 5rem;
    left: 50%;
    padding: 2.5rem;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);

    @include respondto(maxTablet) {
      padding: 2rem;
    }

    &:before {
      font-size: 2.5rem;
      left: 50%;
      margin-left: 3px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @include respondto(maxTablet) {
        font-size: 1.8rem;
        margin-left: 1px;
      }
    }
  }

  .description-item {
    color: $grey-dark4;
    margin-bottom: 6rem;

    @include respondto(phone) {
      font-size: 1.8rem;
      line-height: 2.5rem;
      margin: 0 auto;
      width: 70%;
    }
  }

  .tag-item {
    background-color: rgba(0, 0, 0, 0.3);

    &,
    &--light {
      border-radius: 5rem;
      left: 2rem;
      padding: 1rem 2rem;
      position: absolute;
      top: 3.5rem;
      z-index: 2;
    }

    span {
      color: $white;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 700;
      font-size: 1.2rem;
      letter-spacing: 1.2px;
      line-height: 1.4rem;
      text-transform: uppercase;
    }

    &--light {
      background-color: $white;

      span {
        color: $grey-dark2;
      }
    }
  }

  .img-wrapper {
    height: 25rem;
    margin-bottom: 2rem;

    @include respondto(tablet) {
      height: 15rem;
    }

    @include respondto(phone) {
      height: 20rem;
    }

    img {
      border-radius: 1rem;
    }

    &-lg {
      height: 55rem;

      @include respondto(tablet) {
        height: 45rem;
      }

      &:before {
        background-color: $black;
        border-radius: 1rem;
        content: '';
        height: 100%;
        left: 0;
        width: 100%;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .testimonial-item {
    position: absolute;
    top: 10rem;
    left: 2rem;

    p,
    span {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 300;
      margin-left: 2rem;

      @include respondto(desktopSmall) {
        margin-left: 1rem;
      }

      @include respondto(maxTablet) {
        margin-left: 0;
      }
    }

    p {
      color: $white;
      font-size: 2.8rem;
      line-height: 3.7rem;
      margin-bottom: 2.5rem;
      max-width: 30rem;

      @include respondto(tablet) {
        font-size: 1.8rem;
        line-height: 2.7rem;
      }
    }

    span {
      color: rgba(255, 255, 255, 0.6);
      display: block;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-transform: uppercase;
    }
  }

  .testimonial-content {
    padding: 5rem 0;

    &:before,
    &:after {
      color: $white;
      content: "\f10e";
      font-family: FontAwesome;
      font-size: 3rem;
      position: absolute;
    }

    &:before {
      left: 0;
      top: 0;
      transform: scaleX(-1);
    }

    &:after {
      bottom: 0;
      right: 2rem;

      @include respondto(desktop) {
        right: 0;
      }
    }

    p {
      margin-bottom: 2.5rem;

      @include respondto(phone) {
        margin-left: 0;
      }
    }
  }

  .content-block {
    min-height: 28rem;
    padding-left: 4rem;

    @include respondto(tablet) {
      padding-left: 2rem;
    }

    @include respondto(phone) {
      height: initial;
      padding-left: 3rem;
    }

    .wrapper {
      margin-bottom: 2rem;
      max-width: 29rem;

      @include respondto(phone) {
        max-width: 90%;
      }
    }

    .title {
      a {
        color: #333333;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 300;
        font-size: 2.1rem;
        line-height: 3.2rem;

        @include respondto(phone) {
          font-family: 'Poppins', sans-serif!important;
          font-weight: 400;
          line-height: 2.9rem;
        }
      }
    }

    .description {
      color: $grey-dark3;
      font-family: 'Poppins', sans-serif!important;
      font-weight: 300;
      font-size: 1.6rem;
      line-height: 2.6rem;

      @include respondto(phone) {
        line-height: 2.1rem;
      }
    }
  }

  .author-block {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 2rem;

    @include respondto(phone) {
      margin: 2rem 0;
    }

    p {
      color: $grey-dark5;
      font-size: 1.4rem;
      line-height: 2.3rem;
      text-transform: uppercase;
    }
  }
}