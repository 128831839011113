.apr-info-content {
  p {
    margin-bottom: 5.5rem;

    @include respondto(phone) {
      margin-bottom: 2rem;
    }
  }

  .list-block {
    p {
      margin-bottom: 2rem;
    }
  }

  .bullet-list-custom {
    @include respondto(phone) {
      max-width: 20rem;
      margin: 0 auto 3rem auto;
    }
  }

  .phone-order {
    @include respondto(phone) {
      @include flex($direction: column);

      .video-container {
        order: 1;
      }

      >div {
        &:first-child {
          order: 2;
        }
        &:last-child {
          order: 3;
        }
      }
    }
  }

  .videoWrapper {
    padding-bottom: 43.25%;
  }

  .video-info {
    margin-bottom: 0;
  }
}