.dealer-info {
  background-color: $grey-light;
  padding: 4rem 0 5rem 0;

  @include respondto(phone) {
    padding-bottom: 2rem;
  }

  .container {
    margin: 0 auto;
    max-width: 75rem;
  }

  p {
    color: $black;
  }

  .main-content {
    @include respondto(phone) {
      margin: 0 auto;
      max-width: 22rem;
    }

    >p {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 2.2rem; //to match design

      span {
        font-family: 'Poppins', sans-serif!important;
        font-weight: 500;

        @include respondto(phone) {
          display: block;
        }
      }
    }
  }

  .content-wrapper {
    @include flex($justify: space-between);
  }

  .sm-item {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 300;
  }

  .contact-info {

    p,
    a {
      font-family: 'Poppins', sans-serif!important;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.3rem;
    }

    a {
      color: $blue-dark10;

      &:hover {
        color: $blue-light5;
      }
    }

    p {
      span {
        &:first-of-type {
          font-family: 'Poppins', sans-serif!important;
          font-weight: 500;
        }
      }
    }

    &.visible-xs {
      margin: 1rem auto 0 auto;
      width: 18rem;
      ;
    }
  }

  .link-item {
    border-radius: .3rem;
    width: 20%;
    height: 14rem;

    &:not(:last-of-type) {
      margin-right: 1rem;

      @include respondto(phone) {
        margin-right: 0;
      }
    }

    @include respondto(phone) {
      width: 14rem;
    }

    img {
      display: inline-block;
      margin-bottom: 1rem;
    }

    form>div {
      cursor: pointer;
      padding-bottom: 1.7rem !important;
      transition: all .3s;
    }

    form>div,
    a {
      color: $blue-dark10;
      display: block;
      height: 100%;
      padding: 2.5rem 2rem 1rem 2rem;

      span {
        color: #354052;
        display: block;
        font-size: 1.1rem;
        line-height: 1em;
      }

      &.active {
        background-color: $blue-dark8;

        path {
          fill: $white;
        }

        span {
          color: $white;
        }
      }

      &.no-fill-path:hover,
      &.no-fill-path:focus {
        path {
          stroke: $white;
        }
      }

      &:hover,
      &:focus {
        background-color: $blue-dark8;

        span {
          color: $white;
        }
      }

      &:hover:not(.no-fill-path),
      &:focus:not(.no-fill-path) {
        path {
          fill: $white;
        }
      }
    }

    svg {
      height: 8rem;
    }

    &.bordered-item {
      border: 1px solid #E6EBF0;
    }

    &.detailed-item {
      background-color: #F5F8FA;

      a {
        color: $blue-dark10;
        padding: 0;

        &:hover,
        &:focus {
          background-color: inherit;

          span {
            color: #354052;
          }
        }
      }

      span {
        display: block;
      }

      .top-content {
        background-color: $white;
        padding: 1rem 1rem 2rem 1rem;
      }

      .title {
        color: #3096C9;
        font-family: 'Poppins', sans-serif!important;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.3rem;
      }

      .price {
        font-size: 2.4rem;
        line-height: 2.9rem;
      }

      .subtitle {
        font-size: .8rem;
        line-height: 1rem;
      }

      .xsm {
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 2rem 0;
      }
    }

    @include respondto(phone) {

      &:not(.detailed-item) {
        margin: 0 auto 1rem auto;
        width: 14rem;
      }

      &.detailed-item {
        margin: 0 auto 1rem auto;
        width: 16rem;
      }
    }
  }

  .number-item {
    background-color: $red;
    border-radius: 100%;
    color: $white;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 500;
    font-size: 1rem;
    height: 1.6rem;
    line-height: 1.8rem;
    top: -6px;
    right: -6px;
    width: 1.6rem;

  }
}