.slider-wrapper {
  @include respondto(tablet) {
    padding: 0 1.5rem;
  }
  .products-slider {
    margin-bottom: 10rem;
    padding: 0;

    .product-wrapper {
      padding: .5rem;

      @include respondto(phone) {
        padding: 0;
      }

      .product-content {
        background-color: $white;
        border-radius: .6rem;
        box-shadow: 1px 2px 3px 0 rgba(0,0,0,0.1);
        margin: 4px;
        text-align: center;

        &:hover,
        &:focus {
          border: 4px solid #C8E1EE;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
          margin: 0;
        }

        .inner-wrapper {
          padding: 3rem 3rem 0;
        }

        .img-wrapper  {
          height: 18rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @include respondto(desktopSmallAndTablet) {
            height: 20rem;
          }

          @include respondto(desktopSmall) {
            height: 12rem;
          }
        }

        h4 {
          color: $grey-dark6;
          font-family: 'Poppins', sans-serif!important;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.5rem;
          padding: 2rem;
        }
      }
    }

    .slick-arrow {
      color: #979797;
      cursor: pointer;
      font-size: 5rem;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;

      &.prev-arrow {
        left: -2.5rem;
      }

      &.next-arrow {
        right: -2.5rem;
      }
    }

    @include respondto(maxTablet) {
      margin: 0 0 5rem;
    }

    &-sm {
      margin-bottom: 8.5rem;
    }
  }
}