.three-items {
  padding: 12rem 0 5rem 0;

  @include respondto(phone) {
    border-bottom: 0;
    padding: 3rem 0 0 0;
  }

  .title {
    margin-bottom: 7.3rem;

    @include respondto(phone) {
      margin-bottom: 4rem;
    }
  }

  img {
    margin-bottom: 3.2rem;
  }

  span,
  p {
    margin: 0 auto;
    max-width: 20rem;
  }

  span {
    color: $grey-dark;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    color: $grey;
    font-size: 1.6rem;
    line-height: 2.3rem;
    font-family: 'Poppins', sans-serif!important;
    font-weight: 400;
  }

  .button {
    margin-top: 7.5rem;

    @include respondto(phone) {
      margin-top: 0;
    }
  }
}
