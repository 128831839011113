.checkbox-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}

.checkbox-custom-label {
  color: $blue-dark7;
  font-size: 4.8rem;
  font-weight: normal;
  line-height: 5.8rem;
  letter-spacing: -1.85px;
  ;
  position: relative;
  outline: none !important;

  @include respondto(phone) {
    font-size: 2.4rem;
    line-height: 5.8rem;
    letter-spacing: -0.92px;
  }
}

.checkbox-custom+.checkbox-custom-label:before {
  background: #fff;
  border: 2px solid #ddd;
  content: '';
  display: inline-block;
  height: 2.5rem;
  padding: 2px;
  margin-right: .5rem;
  text-align: center;
  vertical-align: middle;
  width: 2.5rem;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
  background: $green-light11;
  box-shadow: inset 0px 0px 0px 2px #fff;
}

.checkbox-custom:focus+.checkbox-custom-label {
  outline: 1px solid #ddd;
  /* focus style */
}

// No copy
.checkbox-custom-label.no-copy {
  line-height: 1.8rem;
}

.checkbox-custom.no-copy+.checkbox-custom-label.no-copy:before {
  margin-right: 0;
}

// round checkbox
.round-checkbox {
  position: relative;
  width: 100%;

  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 1rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1rem;

    &:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 4px;
      left: 11px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 7px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"]:checked+label {
    background-color: $blue-dark8;
    border-color: $blue-dark8;
  }

  input[type="checkbox"]:checked+label:after {
    opacity: 1;
  }

  span {
    color: #A1A1A1;
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: 0.26px;
    margin-left: 2rem;
    width: 20rem;
  }
}