@import '../../assets/scss/includes/_core/1-variables';
@import '../../assets/scss/includes/_core/2-mixin';

.picid-container {
    background: url('../../static/images/picid_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
    padding-top: 80px;

    .picid-form {
        max-width: 450px;
        margin: 0 auto;
        padding: 20pt 30pt;
        background-color: white;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        
        @include respondto(phone) {
            max-width: 350px;
        }

        @include respondto(phoneSm) {
            max-width: 250px;
            padding: 15pt 10pt;
        }

        .form-block {
            position: relative;
            max-width: 100%;
            margin-bottom: 15pt;
            
            label {
                font-size: 18px;
                line-height: 22px;
                color: #a1a1a1;
                margin-bottom: 5pt;
                font-family: 'Poppins', sans-serif!important;
                font-weight: 400;
            }

            input {
                width: 100%;
                height: 30px;
                padding: 16pt 0pt 17pt 10pt;
                color: #757575;
                font-family: 'Poppins', sans-serif!important;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                line-height: 17px;
                border-radius: 0px;
            }

            p.error {
                font-size: 12px;
                color: red;
                border: none!important;
            }
        }

        .submit-picid {
            position: relative;
            height: 40px;
            padding: 0 30pt;
            display: block;
            margin: 0 auto;
            margin-top: 20pt;
            border-radius: 8px;
            background: $blue-dark8;
            font-family: 'Poppins', sans-serif!important;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            color: white;

            @include respondto(phone) {
                font-size: 16px;
            }
        }
    }

    .picid { 
        cursor: pointer;
        max-width: 450px;
        height: 80px;
        margin: 0 auto;
        margin-top: 25pt;
        background: white;
        border-radius: 16px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        position: relative;

        @include respondto(phone) {
            max-width: 350px;
        }

        @include respondto(phoneSm) {
            max-width: 250px;
        }
        
        .picid-find {
            width: 100%;
            text-align: center;
            margin-bottom: 0px;
            color: $blue-dark8;
            font-family: 'Poppins', sans-serif!important;
            font-weight: 400;
            font-size: 28px;
            line-height: 24px;
            position: relative;

            @include respondto(phone) {
                display: flex;
                justify-content: center;
            }

            .copy {
                font-size: 15px;
                color: #36bcee;
                font-family: 'Poppins', sans-serif!important;
                font-weight: 400;
                text-align: right;
                position: absolute;
                right: 20%;
                bottom: calc(100% - 50% - 10px);

                @include respondto(phone) {
                    position: inherit;
                    right: 0%;
                    bottom: 0px;
                    padding-left: 10pt;
                }
            }
        }
        
        .alert {
            max-width: 100%;
            height: 40px;
            margin: 0 auto;
            margin-top: 5pt;
            background: white;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins', sans-serif!important;
            font-weight: 400;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            position: absolute;
            right: -120px;
            
            @include respondto(phone) {
                bottom: -50px;
                margin: 0 auto;
                right: calc(50% - 45px);
            }
        }

        .alert:before {
            content: '';
            position: absolute;
            left: -9px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid white;

            @include respondto(phone) {
                top: -20px;
                left: calc(50% - 10px);
                border-bottom: 10px solid white;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
            }
        }
    }
}