// Gotham
@include font-face('MontserratThin', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-thin');
@include font-face('MontserratLight', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-light');
@include font-face('MontserratRegular', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-regular');
@include font-face('MontserratMedium', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-medium');
@include font-face('MontserratBold', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-bold');
@include font-face('MontserratBlack', '//static.aprilairepartners.com/dashboard/dist/fonts/montserrat/montserrat-black');

// Font Awesome
@include font-face('FontAwesome', '//static.aprilairepartners.com/dashboard/dist/fonts/fontawesome-webfont');

$font-sizes: (14, 16, 18, 20);

@each $font-size in $font-sizes {
  .font-#{ $font-size } {
    font-size: #{ ($font-size / 10) + 'rem' };
  }
}

@each $font-size in $font-sizes {
  .font-#{ $font-size }--xs {
    @include respondto(mobile) {
      font-size: #{ ($font-size / 10) + 'rem' } !important;
    }
  }
}